<template>

    <nav class="c-nav-mobile">

        <input type="checkbox" :id="id" class="c-nav-mobile-state" />

        <label :for="id" class="c-nav-mobile-button" @click="toggle">
            {{label}}
            <span class="c-nav-mobile-button-line"></span>
            <span class="c-nav-mobile-button-line"></span>
            <span class="c-nav-mobile-button-line"></span>
        </label>

        <div class="c-nav-mobile-content" :class="'c-nav-mobile-content--' + orientation">
            <div class="c-nav-mobile-content-inner o-flex o-flex-auto o-flex-nowrap o-flex-column o-flex-justify-content-between">
                <ul class="c-nav-mobile-content-list" >
                    <li v-for="item in items">
                        <a class="u-padding-xs" :href="item.url" :class="item.class">
                            <span class="u-padding-bottom-xs">{{item.label}}</span>
                        </a>
                    </li>
                    <li class="c-nav-mobile-content-lang u-text-center">
                        <a class="u-padding-xs" :class="{ 'active' : lang.active == true }" :href="lang.url" v-for="lang in languages">
                            <span class="u-padding-bottom-xs u-margin-x-sm">{{ lang.label }}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

    </nav>

</template>

<script>

export default {

    props: {

        id: {
            type: String,
            required: true
        },
        orientation: {
            type: String,
            required: true,
            default: 'overlay'
        },
        label: {
            type: String,
            required: false
        },
        items: {
            type: Array,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },

    methods: {

        toggle: function (e) {

            let body = document.querySelector('.o-body');
            body.classList.toggle('is-' + this.orientation);
        }
    }
};

</script>

<style>

</style>
