var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "c-nav-mobile" }, [
    _c("input", {
      staticClass: "c-nav-mobile-state",
      attrs: { type: "checkbox", id: _vm.id }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "c-nav-mobile-button",
        attrs: { for: _vm.id },
        on: { click: _vm.toggle }
      },
      [
        _vm._v("\n        " + _vm._s(_vm.label) + "\n        "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" }),
        _vm._v(" "),
        _c("span", { staticClass: "c-nav-mobile-button-line" })
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "c-nav-mobile-content",
        class: "c-nav-mobile-content--" + _vm.orientation
      },
      [
        _c(
          "div",
          {
            staticClass:
              "c-nav-mobile-content-inner o-flex o-flex-auto o-flex-nowrap o-flex-column o-flex-justify-content-between"
          },
          [
            _c(
              "ul",
              { staticClass: "c-nav-mobile-content-list" },
              [
                _vm._l(_vm.items, function(item) {
                  return _c("li", [
                    _c(
                      "a",
                      {
                        staticClass: "u-padding-xs",
                        class: item.class,
                        attrs: { href: item.url }
                      },
                      [
                        _c("span", { staticClass: "u-padding-bottom-xs" }, [
                          _vm._v(_vm._s(item.label))
                        ])
                      ]
                    )
                  ])
                }),
                _vm._v(" "),
                _c(
                  "li",
                  { staticClass: "c-nav-mobile-content-lang u-text-center" },
                  _vm._l(_vm.languages, function(lang) {
                    return _c(
                      "a",
                      {
                        staticClass: "u-padding-xs",
                        class: { active: lang.active == true },
                        attrs: { href: lang.url }
                      },
                      [
                        _c(
                          "span",
                          { staticClass: "u-padding-bottom-xs u-margin-x-sm" },
                          [_vm._v(_vm._s(lang.label))]
                        )
                      ]
                    )
                  }),
                  0
                )
              ],
              2
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }