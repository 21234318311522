var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "google-map",
    {
      attrs: {
        zoom: 12,
        center: { lat: 50.9410672, lng: 3.9883624 },
        options: {
          styles: _vm.mapstyles,
          gestureHandling: "cooperative",
          mapTypeControl: false,
          fullscreenControl: false
        }
      }
    },
    [
      _c(
        "gmap-info-window",
        {
          attrs: {
            options: _vm.infoOptions,
            position: _vm.infoWindowPos,
            opened: _vm.infoWinOpen
          },
          on: {
            closeclick: function($event) {
              _vm.infoWinOpen = false
            }
          }
        },
        [_vm._v("\n        " + _vm._s(_vm.infoContent) + "\n    ")]
      ),
      _vm._v(" "),
      _vm._l(_vm.markers, function(m, i) {
        return _c("gmap-marker", {
          key: i,
          attrs: { position: m.position, clickable: true },
          on: {
            click: function($event) {
              _vm.toggleInfoWindow(m, i)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }